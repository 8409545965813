




























































import { Vue } from 'vue-property-decorator'
import Component from 'vue-class-component'

import ProgressList from '@/components/shared/ProgressList.vue'
import InitialAudioCheckMessage from '@/components/audio/InitialAudioCheckMessage.vue'
import SuccessfulAudioCheck from '@/components/audio/SuccessfulAudioCheck.vue'
import BackgroundAudioCheck from '@/components/audio/BackgroundAudioCheck.vue'

import Translations from '@/store/modules/translations'
import { getModule } from 'vuex-module-decorators'

const translations = getModule(Translations) // Store accessor.

// Translation Keys
const TITLE_KEY = 'backgroundAudioCheckTitleText'
const PERM_KEY = 'backgroundAudioCheckPermissionForMicText'
const QUIET_KEY = 'backgroundAudioCheckFindQuietSpaceText'
const ERROR_PERMISSION_KEY = 'backgroundAudiPermissionErrorText'
const ERROR_KEY = 'backgroundAudioCheckErrorText'
const CHECK_BUTTON_KEY = 'backgroundAudioCheckCheckText'

@Component({
  components: {
    ProgressList,
    InitialAudioCheckMessage,
    BackgroundAudioCheck,
    SuccessfulAudioCheck
  }
})
export default class AudioCheckView extends Vue {
  /**
   * This component is the Audio Check View.
   * It renders the boilerplate text used for this view,
   * then it conditioanlly renders a component based upon the current state.
   * I.e:
   * - if the User has not yet consented to recording they will be prompted to allow recording. (InitialAudioCheckMessage)
   * - if the User has consented to recording background audio and the background audio check is in the process of being completed,
   *  it will show the checking component (CheckBackgroundAudio)
   * - if the User has consented to recording background audio and the check is sucessessful,
   *  then it will render the success component (SuccessfulAudioCheck)
   */

  microphonePermissionGranted = false
  backgroundAudioCheckedDone = false
  currentStep = 0
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mediaStream: any = null

  titleText = ''
  permissionText = ''
  quietSpaceText = ''
  errorText = ''
  checkButton = ''

  async mounted () {
    this.titleText = translations.getTranslations(TITLE_KEY)
    this.permissionText = translations.getTranslations(PERM_KEY)
    this.quietSpaceText = translations.getTranslations(QUIET_KEY)
    this.errorText = translations.getTranslations(ERROR_KEY)
    this.checkButton = translations.getTranslations(CHECK_BUTTON_KEY)
  }

  handleMediaStreamAquired (stream: MediaStream): void {
    console.debug('handleMediaStreamAquired')

    this.mediaStream = stream
    this.currentStep = 1
  }

  handlePermissionDeclined (): void {
    this.currentStep = 4
    this.errorText = translations.getTranslations(ERROR_PERMISSION_KEY)
  }

  handleCheckFailed (success: boolean): void {
    if (success) {
      this.currentStep = 2
    } else {
      this.currentStep = 3

      this.errorText = translations.getTranslations(ERROR_KEY)
    }
  }
}
