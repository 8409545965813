




















import { Vue } from 'vue-property-decorator'
import Component from 'vue-class-component'

import Translations from '@/store/modules/translations'
import { getModule } from 'vuex-module-decorators'

const translations = getModule(Translations) // Store accessor.

const BEGIN_KEY = 'backgroundAudioCheckBeginRecordingText'
const SUCCESS_MESSAGE_KEY = 'backgroundAudioCheckAudioGoodText'
/**
 * This component is called when user.ts state:
 * - permissionToCheckAudio === true
 * - audioCheckIsSuccessful === true
 * The purpose of this component is to display the success page once the background audio check has been completed.
 * Then the page routes the user to the recordings page.
 */
@Component
export default class SuccessfulAudioCheck extends Vue {
  beginText =''
  successText = []

  async mounted () {
    this.beginText = translations.getTranslations(BEGIN_KEY)
    this.successText = translations.getTranslations(SUCCESS_MESSAGE_KEY)
  }

  handleButtonClick () {
    this.routeToNextPage()
  }

  // component method to route to the next page.
  routeToNextPage () {
    this.$router.push('/recordings')
  }
}
