























import { Vue } from 'vue-property-decorator'
import Component from 'vue-class-component'

import Translations from '@/store/modules/translations'
import { getModule } from 'vuex-module-decorators'

import { AUDIO_CONSTRAINTS } from '@/constants'

const translations = getModule(Translations) // Store accessor.

const CHECK_BUTTON_KEY = 'backgroundAudioCheckCheckText'
const HEADSET_KEY = 'backgroundAudioCheckHeadsetText'

const UI_TEXT_TOAST_ERROR_TITLE = 'toastErrorErrorTitle'
const UI_TEXT_TOAST_ERROR_BACKGROUND_NOISE = 'toastErrorBackgroundNoise'

@Component
export default class InitialAudioCheckMessage extends Vue {
    headsetText = ''
    checkButtonText = ''

    async mounted () {
      this.headsetText = translations.getTranslations(HEADSET_KEY)
      this.checkButtonText = translations.getTranslations(CHECK_BUTTON_KEY)
    }

    // component method called when handling the click of the button
    handleCheckBackgroundNoiseClicked () {
      this.updatePermissionToRecordState()
    }

    async updatePermissionToRecordState () {
      try {
        // If we can't aquire a MediaStream, it either means the microphone hasn't got permission or something's blocking
        // aquiring the microphone audio input
        const stream: MediaStream = await navigator.mediaDevices.getUserMedia(AUDIO_CONSTRAINTS)

        this.$emit('on-media-stream-acquired', stream)
      } catch (mediaStreamError) {
        /* handle the error */
        console.error(mediaStreamError)
        this.$bvToast.toast(translations.getTranslations(UI_TEXT_TOAST_ERROR_BACKGROUND_NOISE), {
          title: translations.getTranslations(UI_TEXT_TOAST_ERROR_TITLE),
          variant: 'danger',
          solid: true
        })

        this.$emit('on-microphone-permission-declined')
      }
    }
}
